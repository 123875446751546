// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animirani-film-js": () => import("./../src/pages/animirani-film.js" /* webpackChunkName: "component---src-pages-animirani-film-js" */),
  "component---src-pages-autori-js": () => import("./../src/pages/autori.js" /* webpackChunkName: "component---src-pages-autori-js" */),
  "component---src-pages-dokumenti-js": () => import("./../src/pages/dokumenti.js" /* webpackChunkName: "component---src-pages-dokumenti-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-video-prezentacije-js": () => import("./../src/pages/video-prezentacije.js" /* webpackChunkName: "component---src-pages-video-prezentacije-js" */),
  "component---src-templates-book-template-js": () => import("./../src/templates/BookTemplate.js" /* webpackChunkName: "component---src-templates-book-template-js" */),
  "component---src-templates-books-template-js": () => import("./../src/templates/BooksTemplate.js" /* webpackChunkName: "component---src-templates-books-template-js" */)
}

